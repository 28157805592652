import React from 'react';
import {
  Loader,
  GenerateNotification,
  buildNotification,
  TextAreaInput,
  CopyTextButton,
} from 'smart-react';
import { QRCode } from '@progress/kendo-react-barcodes';
import { Input } from '@progress/kendo-react-inputs';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
import { getDeviceRegistrationStatus } from '../../../SmartRF/DeviceEnrollment/Services/DeviceEnrollmentService';
import './QRCodeSlider.scss';
import { Label } from '@progress/kendo-react-labels';
import { SliderCard, extractNumberFromString } from 'smart-react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Form, Field } from '@progress/kendo-react-form';
/**
 * QRCodeSlider component for showing dropdown data on add screens
 */
const QRCodeSlider = ({
  setShowQRModal,
  singleProfile,
  isReloadData,
  setIsReloadData,
}) => {
  const [devcod, setDevcod] = React.useState('');
  const [termID, setTermID] = React.useState('');
  const [selectedProfile, setSelectedProfile] = React.useState(null);
  const [dialogueLoading, setDialogueLoading] = React.useState(false);
  const [mtfTermType, setMtfTermType] = React.useState({
    value: 'handheld',
    text: 'Handheld',
  });
  const [localeData, setLocaleData] = React.useState([]);
  const [mtfDisplayWidth, setMtfDisplayWidth] = React.useState(20);
  const [mtfDisplayHeight, setMtfDisplayHeight] = React.useState(16);
  const [localeId, setLocaleId] = React.useState({});
  const [qrData, setQRData] = React.useState('');
  const [isQrCode, setIsQrCode] = React.useState(true);
  /**
   * Function to handle changes in Locale_id dropdown.
   * @param e
   */
  const handleLocaleIdChange = (e) => {
    setLocaleId(e.value);
  };
  /**
   * Function to handle changes in MTF Terminal Type dropdown.
   * @param type
   */
  const handleMtfTermTypeChange = (type) => {
    setMtfTermType(type);
    // Update display dimensions based on the selected type
    if (type.value === 'vehicle') {
      setMtfDisplayWidth(40);
      setMtfDisplayHeight(8);
    } else {
      setMtfDisplayWidth(20); // Default values for handheld
      setMtfDisplayHeight(16);
    }
  };

  /**
   * generate QR.
   * @param profile
   * @param seq_number
   */
  const generateQR = async (profile, seq_number = null) => {
    setShowQRModal(true);
    setDialogueLoading(true);

    // Extracting sequence number from auto_devcod_seqnum
    const sequenceNumber =
      seq_number != null ? seq_number : parseInt(profile.auto_devcod_seqnum);
    // Incrementing the sequence number by 1
    const nextSequenceNumber = sequenceNumber + 1;

    const numberPart = nextSequenceNumber.toString().padStart(7, '0');
    const generatedDevcod = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
    const generatedTermId = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
    const data = {
      profileSetupGUID: profile?.srfqrvm?.SRFProfilesSetupId,
      smartAppsURL: profile?.srfqrvm?.SmartAppURL,
      mocaURL: profile?.srfqrvm?.MOCAURL,
      mtfURL: profile?.srfqrvm?.MTFURL,
      devcod: generatedDevcod,
      termId: generatedTermId,
      mtfTermType: mtfTermType?.value,
      mtfDisplayWidth: mtfDisplayWidth,
      mtfDisplayhight: mtfDisplayHeight,
      localeId: localeId?.value, // Set Locale_id
      mtfVendorName: `${profile?.srfqrvm?.MTFVendorName}`, // Set Mtf_ven_nam
    };
    const jsonStr = JSON.stringify(data);
    const base64Str = btoa(jsonStr);
    setQRData(base64Str);
    setDevcod(generatedDevcod);
    setTermID(generatedTermId);
    setDialogueLoading(false);
  };

  /**
   * check And Update QR.
   * @param profileId
   */
  const checkAndUpdateQR = async (profileId) => {
    if (selectedProfile) {
      try {
        const response = await getDeviceRegistrationStatus({
          profileSetupId: profileId,
        });
        const currentSeqNumber = parseInt(response?.Payload);
        const seqNumberFromTermID = parseInt(extractNumberFromString(termID));
        if (
          seqNumberFromTermID < currentSeqNumber ||
          seqNumberFromTermID === currentSeqNumber
        ) {
          setDialogueLoading(true);
          GenerateNotification(
            buildNotification({
              title: 'Successfully Registered',
              description: `SM${currentSeqNumber.toString().padStart(7, '0')} registered successfully!`,
              style: 'success',
            }),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
          );
          // Add a 1-second delay
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const nextSequenceNumber = currentSeqNumber + 1;
          const numberPart = nextSequenceNumber.toString().padStart(7, '0');
          const generatedDevcod = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
          const generatedTermId = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
          setDevcod(generatedDevcod);
          setTermID(generatedTermId);
          generateQR(selectedProfile, currentSeqNumber);
          setDialogueLoading(false);
          setIsReloadData(!isReloadData);
          setSelectedProfile((prevState) => ({
            ...prevState,
            auto_devcod_seqnum: currentSeqNumber,
          }));
        }
      } catch (error) {
        console.error('Error checking device registration status:', error);
      }
    }
  };

  React.useEffect(() => {
    if (singleProfile) {
      setSelectedProfile(singleProfile);
      setLocaleData([
        {
          value: singleProfile?.srfqrvm?.LocaleId,
          text: singleProfile?.srfqrvm?.LocaleId,
        },
      ]);
      setLocaleId({
        value: singleProfile?.srfqrvm?.LocaleId,
        text: singleProfile?.srfqrvm?.LocaleId,
      });
      generateQR(singleProfile);
    }
  }, [singleProfile]);

  React.useEffect(() => {
    if (selectedProfile) {
      // Call the function initially
      checkAndUpdateQR(selectedProfile?.srfqrvm?.SRFProfilesSetupId);

      // Set interval to repeatedly check and update QR every 5 seconds
      const intervalId = setInterval(() => {
        checkAndUpdateQR(selectedProfile?.srfqrvm?.SRFProfilesSetupId);
      }, 5000);

      // Clear interval when component unmounts to prevent memory leaks
      return () => clearInterval(intervalId);
    }
  }, [selectedProfile, termID]);

  React.useEffect(() => {
    if (selectedProfile) {
      generateQR(selectedProfile);
    }
  }, [mtfTermType, localeId]);

  const handleCopy = async (event) => {
    event.stopPropagation(); // Prevent the click from bubbling up to the parent
    try {
      await navigator.clipboard.writeText(qrData);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  return (
    <SliderCard
      title={'Generate QR'}
      handleSlide={() => {
        setShowQRModal(false);
        setSelectedProfile(null);
      }}
      className='ai-slider content-slider-form'
    >
      <SliderCard.Body>
        {dialogueLoading && <Loader />}
        {qrData && (
          <Form
            render={(formRenderProps) => (
              <>
                <div>
                  <div
                    className='flip-card-container'
                    onClick={() => setIsQrCode(!isQrCode)}
                  >
                    <div className={`flip-card ${!isQrCode ? 'flipped' : ''}`}>
                      <div className='flip-card-front qrCode k-text-center'>
                        <div
                          key='qr-code'
                          className='content-item qr-code-content k-bg-white'
                        >
                          <QRCode value={qrData} size={270} />
                        </div>
                      </div>
                      <div className='flip-card-back baseString single-field-row'>
                        <div
                          key='base64'
                          className='content-item base64-field field-wrapper data-field-wrapper k-bg-white app-key-wrapper'
                        >
                          <Field
                            key={'qrData'}
                            id={'qrData'}
                            name={'qrData'}
                            data={qrData}
                            label={'Base 64:'}
                            type={'text'}
                            component={TextAreaInput}
                            row={6}
                            readonly
                            optional={false}
                          />
                          <CopyTextButton text={qrData} keyName={'Base 64'} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <hr />
                  <Label>Terminal Type</Label>
                  <DropDownList
                    data={[
                      { value: 'handheld', text: 'Handheld' },
                      { value: 'vehicle', text: 'Vehicle' },
                    ]}
                    value={mtfTermType}
                    textField='text'
                    dataItemKey='text'
                    className='!k-mb-3'
                    onChange={(e) => handleMtfTermTypeChange(e.target.value)}
                  />
                  <Label>Device Display Width:</Label>
                  <Input
                    value={mtfDisplayWidth}
                    readOnly
                    className='!k-mb-3'
                    disabled // Non-editable for now
                  />
                  <Label>Device Display Height:</Label>
                  <Input
                    value={mtfDisplayHeight}
                    readOnly
                    className='!k-mb-3'
                    disabled // Non-editable for now
                  />
                  {/* Dropdown for selecting Locale_id */}
                  <Label>Locale ID</Label>
                  <DropDownList
                    data={localeData} // Only one value for now
                    value={localeId}
                    textField='text'
                    dataItemKey='text'
                    onChange={handleLocaleIdChange}
                    className='!k-mb-3'
                  />
                  <Label>Devcod</Label>
                  <Input
                    value={devcod}
                    onChange={(e) => {
                      setDevcod(e.target.value);
                      handleInputChange(e, 'devcod');
                    }}
                    required={true}
                    className='!k-mb-3'
                    readOnly
                  />
                  <Label>Term ID</Label>
                  <Input
                    value={termID}
                    onChange={(e) => {
                      setTermID(e.target.value);
                      handleInputChange(e, 'termID');
                    }}
                    required={true}
                    className='!k-mb-3'
                    readOnly
                  />
                </div>
              </>
            )}
          />
        )}
      </SliderCard.Body>
    </SliderCard>
  );
};

export default QRCodeSlider;
